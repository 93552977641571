<script setup lang="ts">
import type { SeoBreadcrumb } from '@/types/cms';

defineOptions({
  name: 'ThankYou',
});

const { $urls, $mopI18n, $storyblokLivePreview } = useNuxtApp();
const route = useRoute();
const classNameRef = ref(['']);
const { getCmsStory, trackPageView, cmsStoryModelRef, loadingRef } = useMopCms();
const { getBreadcrumbSchema } = useMopSeo();
const { initTransition } = useMopPageTransitionClient();
const { handleHeadForCmsPage } = useMopSeo();
const { fetchJob } = useMopJob();
const jobId = route.query.jobId as string;

initTransition(classNameRef, loadingRef);

onMounted(async () => {
  const language = await fetchJob(jobId);
  await getCmsStory('/career/start-creating-with-us/our-jobs/thank-you', {
    language: language || 'default',
  });
  trackPageView('Career');
});

watch(
  cmsStoryModelRef,
  (cmsStoryModel) => {
    if (!cmsStoryModel.isInitialized()) {
      return;
    }
    if (cmsStoryModel.hasErrorNotFound()) {
      throw createError({
        statusCode: 404,
        message: 'PAGE_NOT_FOUND',
      });
    }
  },
  { immediate: true },
);

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

const seoHeadRef = computed(() => {
  const careerLink: string = $mopI18n.localePath($urls.CAREER);
  const startCreatingLink: string = $mopI18n.localePath($urls.CAREER_START_CREATING);

  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }

  const breadcrumbs: SeoBreadcrumb[] = [
    {
      name: $mopI18n.t('locale.blog.name_career'),
      url: careerLink,
    },
    {
      name: $mopI18n.t('locale.jobs.start_creating_with_us'),
      url: startCreatingLink,
    },
    {
      name: $mopI18n.t('locale.jobs.thank_you'),
      url: route.path,
    },
  ];

  return {
    ...handleHeadForCmsPage(cmsStoryModelRef.value),
    ...getBreadcrumbSchema(breadcrumbs),
  };
});

useHead(seoHeadRef);
</script>

<template>
  <div :class="['thank-you', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <MopCmsContentElementsSlot id="content-page" :elements="cmsStoryModelRef.getContentElements()" />
    </div>
  </div>
</template>
